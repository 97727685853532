import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import get from 'lodash/get'

class WhyNav extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const WNL = ({ to, children }) => (
      <li className="nav-item">
        <Link
          to={to}
          className={`outline-primary nav-link ${
            to == this.props.path ? 'active' : ''
          }`}
        >
          {children}
        </Link>
      </li>
    )
    return (
      <nav className="d-flex flex-md-row-reverse flex-lg-column justify-content-around align-items-center">
        <h1 style={{ textAlign: 'center', margin: '0 1rem' }}>
          Why {siteTitle}?
        </h1>
        <ul className="nav nav-tabs flex-column flex-md-row justify-content-center m-2">
          <WNL to="/why/overview/">Overview</WNL>
          <WNL to="/why/the-science/">The Science</WNL>
          <WNL to="/why/testimonials/">Testimonials</WNL>
          <WNL to="/why/faq/">FAQ</WNL>
        </ul>
      </nav>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <WhyNav data={data} {...props} />}
  />
)
