import React from 'react'

import ListGroup from 'react-bootstrap/ListGroup'

const Testimonial = ({ node, ...props }) => (
  <ListGroup.Item as="blockquote" className="blockquote" {...props}>
    <p className="mb-1">
      {node.testimonial.childMarkdownRemark.rawMarkdownBody}
    </p>
    <footer className="blockquote-footer">
      {node.author}
      {node.authorPosition ? ` , ${node.authorPosition}` : ''}
    </footer>
  </ListGroup.Item>
)

export default Testimonial
