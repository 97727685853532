import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'

import ListGroup from 'react-bootstrap/ListGroup'

import WhyNav from '../../components/why-nav'
import Layout from '../../components/layout'
import Testimonial from '../../components/testimonial'

export default class Testimonials extends React.Component {
  render() {
    const testimonials = get(this, 'props.data.allContentfulTestimonial.edges')
    const image = get(this, 'props.data.file.childImageSharp.fixed')
    // allow auto meta description
    return (
      <Layout pageTitle="Testimonials">
        <WhyNav path={this.props.path} />
        <div className="row justify-content-between align-items-center m-3">
          <h2>Testimonials</h2>
          <Img fixed={image} alt="children learning" />
        </div>
        <ListGroup>
          {testimonials.map(({ node }, i) => (
            <Testimonial key={i} node={node} />
          ))}
        </ListGroup>
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    allContentfulTestimonial(sort: { fields: updatedAt }) {
      edges {
        node {
          author
          authorPosition
          testimonial {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
    file(relativePath: { eq: "why/testimonials.jpg" }) {
      childImageSharp {
        fixed(quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
